/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from "react";
import BaseContainer from "core/BaseContainer/";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as CONSTANTS from "../../../AppConfig/constants";
import * as actionTypes from "app/store/action/";
import * as common from "app/utility/common";
import { FORCE_ALPHANUMERIC_PASSWORD } from "app/AppConfig/features";
import Input from "../../../../core/components/Input/";
import Checkbox from "../../../../core/components/Checkbox/";
import Button from "../../../../core/components/Button/";
// import Dialog from "core/components/Dialog";
import ReactHtmlParser from 'react-html-parser';
import Spinner from "core/components/Spinner";
import SocialLogin from "../SocialLogin";
import PhoneInput from "../../components/PhoneInput/";
import oResourceBundle from "app/i18n/";
import withTracker from "core/GoogleAnalytics/";
import Logger from "core/Logger";
import { toast } from "core/components/Toaster/";
import { sendEvents } from "core/GoogleAnalytics/";
import Recaptcha from "../../components/Recaptcha";
import { CleverTap_CustomEvents } from 'core/CleverTap'

import "./index.scss";

const MODULE_NAME = "SignUp";

class SignUp extends BaseContainer {
  /**
   * Represents SignUp.
   * @constructor
   * @param {Object} props - Properties of the object.
   */
  constructor(props) {
    super(props);
    this.state = {
      emailVisible: false,
      mobileVisible: false,
      email: "",
      emailPassword: "",
      mobilePassword: "",
      country: 356,
      phoneNumber: "",
      newsletter: false,
      promotions: false,
      serverLogInErrorMessage: "",
      serverLogInErrorStatus: false,
      isEmailValid: false,
      isMobileValid: false,
      emailPasswordValid: false,
      mobilePasswordValid: false,
      bEnableUpdateBtn: false,
      checkboxChanged: false,
      checkboxChanged1: false,
      checkboxChanged2: false,
      subscriptionCheckBox: false,
      subscriptionCheckBox1: true,
      subscriptionCheckBox2: true,
      subscriptionCheckBox3: true,
      subscriptionCheckBox4: false,
      captchaVerified:false,
      recaptcha_token:"",
      expired: "false",

    };
    this.minimumPasswordLength = CONSTANTS.PASSWORD_VALIDATION_CONTENT_LENGTH;
  }

  componentDidMount() {
    this.props.stopLoader();
    this.fnScrollToTop();
    this.checkAlreadyLoggedIn();
  }

  componentDidUpdate() {
    Logger.log(MODULE_NAME, "componentDidUpdate");
    this.checkAlreadyLoggedIn();
    if (this.props.twitterToken && this.props.twitterToken.oauth_token) {
      window.open(
        "https://api.twitter.com/oauth/authenticate?oauth_token=" +
        this.props.twitterToken.oauth_token,
        "_self"
      );
    }
  }
  checkAlreadyLoggedIn() {
    const userDetails = common.getServerCookie(CONSTANTS.COOKIE_USER_OBJECT);
    if (userDetails) {
      this.props.history.push(`/${this.props.locale}`);
    }
  }

  componentWillUnmount() {
    this.setState({
      twitterToken: null
    });
  }

  /**
   * Component Name - SignUp
   *  Handle the Confirm Button after setting the values in Input.
   * @param { null }
   */
  handleConfirmButton(eve) {

    let CountryName = common.getCountryName(this.props.countryCode)

    if (this.state.emailVisible) {
      sendEvents(
        CONSTANTS.REGISTRATION_CATEGORY,
        CONSTANTS.REGISTRATION_ACTION,
        CONSTANTS.LABEL_EMAIL
      );

      CleverTap_CustomEvents("signup_initiated", {
        "method": "email"
      })

      if (this.state.isEmailValid && this.state.emailPasswordValid && this.state.subscriptionCheckBox1 && this.state.subscriptionCheckBox2 && this.state.subscriptionCheckBox3) {
        const oCreateAcctUserData = {
          email: this.state.email,
          password: this.state.emailPassword,
          languageId: CONSTANTS.LANGUAGE_ID[this.props.locale],
          privacyPolicy: this.state.subscriptionCheckBox1,
          isAdult: this.state.subscriptionCheckBox2,
          IsRecommend: this.state.subscriptionCheckBox3,
          newsletter: this.state.newsletter,
          Alpha2code: this.props.countryCode,
          countryName: CountryName,
          registrationPlatform : "web"

        };
        this.props.fnSendNewUserDetails(
          oCreateAcctUserData,
          this.registerEmailSuccess.bind(this, oCreateAcctUserData),
          this.registerEmailError.bind(this)
        );
      }
    } else if (this.state.mobileVisible) {
      sendEvents(
        CONSTANTS.REGISTRATION_CATEGORY,
        CONSTANTS.REGISTRATION_ACTION,
        CONSTANTS.LABEL_MOBILE
      );

      CleverTap_CustomEvents("signup_initiated", {
        "method": "phone"
      })

      if (this.state.isMobileValid && this.state.mobilePasswordValid && this.state.subscriptionCheckBox1 && this.state.subscriptionCheckBox2 && this.state.subscriptionCheckBox3 && this.state.captchaVerified) {
        const phoneNumber = common.getRawNumber(this.state.phoneNumber);
        const oCreateAcctUserData = {
          phoneNumber: phoneNumber,
          password: this.state.mobilePassword,
          languageId: CONSTANTS.LANGUAGE_ID[this.props.locale],
          privacyPolicy: this.state.subscriptionCheckBox1,
          isAdult: this.state.subscriptionCheckBox2,
          isRecommend: this.state.subscriptionCheckBox3,
          newsletter: this.state.newsletter,
          recaptcha_token:this.state.recaptcha_token,
          registrationPlatform : "web"
        };
        this.props.fnSendNewUserDetails(
          oCreateAcctUserData,
          this.registerMobileSuccess.bind(this, oCreateAcctUserData),
          this.registerMobileError.bind(this)
        );
      }
    }
  }
  /**
   * Component Name - SignUp
   * Execute if the new user successfully registered and redirect to input verification page.
   * @param { Object } oCreateAcctUserData- Details of the new user.
   * @returns {undefined}
   */
  registerEmailSuccess(oCreateAcctUserData) {
    let oNewUserDetails = {
      emailVerified: false,
      email: oCreateAcctUserData.email,
      privacyPolicy: oCreateAcctUserData.privacyPolicy,
      isAdult: oCreateAcctUserData.isAdult,
      IsRecommend: oCreateAcctUserData.isRecommend
    };
    this.props.fnSaveNewUserDetails(oNewUserDetails);
    this.props.history.push(`/${this.props.locale}/${CONSTANTS.CONFIRM_EMAIL}`);

    //Send analytics event
    sendEvents(CONSTANTS.SIGNUP_CATEGORY, CONSTANTS.MAIL_ACTION);

    //ClerverTap Events
    CleverTap_CustomEvents("signup_success", {
      "method": "email",
      "country": this.props.countryCode ? this.props.countryCode : localStorage.getItem('country')
    })
  }

  /**
   * Component Name - SignUp
   * Execute if the new user successfully registered and redirect to email verification page.
   * @param { Object } error- Error coming from Backend.
   * @returns {undefined}
   */
  registerEmailError(error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.invalid
    ) {
      let errorMsg = error.response.data.invalid.hasOwnProperty("email")
        ? error.response.data.invalid.email.code
        : error.response.data.invalid.password.code;
      if (errorMsg === CONSTANTS.EMAIL_ALREADY_EXISTS) {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.error_user_email_already_exists,
          toast.POSITION.BOTTOM_CENTER
        );
      } else if (errorMsg === CONSTANTS.PASSWORD_LENGTH_INVALID) {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.password_length_error,
          toast.POSITION.BOTTOM_CENTER
        );
      }
    } else {
      common.showToast(
        CONSTANTS.REGISTER_ERROR_TOAST_ID,
        oResourceBundle.something_went_wrong,
        toast.POSITION.BOTTOM_CENTER
      );
      this.setState({
        serverLogInErrorStatus: true
      });
    }

    //ClerverTap Events
    CleverTap_CustomEvents("signup_failure", {
      "method": "email",
      "country": this.props.countryCode ? this.props.countryCode : localStorage.getItem('country')
    })
  }

  /**
   * Component Name - SignUp
   * Execute if the new user successfully registered and redirect to input verification page.
   * @param { Object } oCreateAcctUserData- Details of the new user.
   * @returns {undefined}
   */
  registerMobileSuccess(oCreateAcctUserData) {
    let oNewUserDetails = {
      emailVerified: false,
      phoneNumber: oCreateAcctUserData.phoneNumber,
      password: this.state.mobilePassword,
      privacyPolicy: oCreateAcctUserData.privacyPolicy,
      isAdult: oCreateAcctUserData.isAdult,
      IsRecommend: oCreateAcctUserData.IsRecommend,
      recaptcha_token:this.state.recaptcha_token
    };
    this.props.fnSaveNewUserDetails(oNewUserDetails);
    this.props.history.push(
      `/${this.props.locale}/${CONSTANTS.MOBILE_VERIFICATION}`
    );
    //Send analytics event
    sendEvents(CONSTANTS.SIGNUP_CATEGORY, CONSTANTS.MOBILE_ACTION);
  }

  /**
   * Component Name - SignUp
   * Execute if the new user successfully registered and redirect to email verification page.
   * @param { Object } error- Error coming from Backend.
   * @returns {undefined}
   */
  registerMobileError(error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.invalid
    ) {
      let errorMsg = error.response.data.invalid.hasOwnProperty("phoneNumber")
        ? error.response.data.invalid.phoneNumber.code
        : error.response.data.invalid.password.code;
      if (errorMsg === CONSTANTS.PHONE_ALREADY_EXISTS) {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.error_user_phone_already_exists,
          toast.POSITION.BOTTOM_CENTER
        );
      } else if (errorMsg === CONSTANTS.INVALID_PHONE_NUMBER) {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.mobile_invalid,
          toast.POSITION.BOTTOM_CENTER
        );
      } else if (errorMsg === CONSTANTS.PASSWORD_LENGTH_INVALID) {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.password_length_error,
          toast.POSITION.BOTTOM_CENTER
        );
      } else {
        common.showToast(
          CONSTANTS.REGISTER_ERROR_TOAST_ID,
          oResourceBundle.something_went_wrong,
          toast.POSITION.BOTTOM_CENTER
        );
      }
    } else {
      common.showToast(
        CONSTANTS.REGISTER_ERROR_TOAST_ID,
        oResourceBundle.something_went_wrong,
        toast.POSITION.BOTTOM_CENTER
      );
      this.setState({
        serverLogInErrorStatus: true
      });
    }

    //ClerverTap Events
    CleverTap_CustomEvents("signup_failure", {
      "method": "phone",
      "country": this.props.countryCode ? this.props.countryCode : localStorage.getItem('country')
    })

  }

  /**
   * Component Name - SignUp
   * Change the state for Dialog Component.
   * @param {null}
   * @returns {undefined}
   */
  handleSignUpError() {
    this.setState({
      serverLogInErrorStatus: false
    });
  }

  /**
   * Component Name - SignUp
   *  Checkbox Changes, Updating the State.
   * @param {object} oEvent - Event hanlder
   */
  handleCheckBox(oEvent) {
    this.setState({
      subscriptionCheckBox: oEvent.target.checked
    });
  }
  handleCheckBox1(oEvent) {
    this.setState({
      subscriptionCheckBox1: oEvent.target.checked,
      checkboxChanged: true
    });
  }

  handleCheckBox2(oEvent) {
    this.setState({
      subscriptionCheckBox2: oEvent.target.checked,
      checkboxChanged1: true
    });
  }

  handleCheckBox3(oEvent) {
    this.setState({
      subscriptionCheckBox3: oEvent.target.checked,
      checkboxChanged2: true
    });
  }

  handleCheckBox4(oEvent) {
    this.setState({
      subscriptionCheckBox4: oEvent.target.checked,
    });
  }

  inputStateChanged(newState) {
    this.setState(newState, this.fnSetContinueButtonEnabled);
  }

  onEmailChange(eve) {
    const text = eve.target.value;
    this.setState({
      email: text
    });
    if (common.isValidEmail(text)) {
      this.setState(
        {
          isEmailValid: true
        },
        this.inputStateChanged
      );
    } else {
      this.setState(
        {
          isEmailValid: false
        },
        this.inputStateChanged
      );
    }
  }

  onPhoneChanged(text, country) {
    Logger.log(MODULE_NAME, "onPhoneChanged: " + text);
    this.setState({
      phoneNumber: text
    });
    this.setState(
      {
        isMobileValid: true
      },
      this.inputStateChanged
    );
    // if (common.isValidPhone(text, country.code)) {
    //   this.setState(
    //     {
    //       isMobileValid: true
    //     },
    //     this.inputStateChanged
    //   );
    // } else {
    //   this.setState(
    //     {
    //       isMobileValid: false
    //     },
    //     this.inputStateChanged
    //   );
    // }
  }
  /**
   * Component Name - Login
   *  Form Inputs Changes, Updating the State and check for the validations.
   *  @param {object} eve - Event hanlder
   */
  handlePasswordOnChange(eve) {
    const password = eve.target.value;
    if (this.state.emailVisible) {
      this.setState({
        emailPassword: password
      });
    } else {
      this.setState({
        mobilePassword: password
      });
    }

    if (password.length < this.minimumPasswordLength) {
      this.updatePasswordValidity(false);
    } else {
      if (FORCE_ALPHANUMERIC_PASSWORD) {
        if (
          common.containsAlphabets(password) &&
          common.containsNumerals(password)
        ) {
          this.updatePasswordValidity(true);
        } else {
          this.updatePasswordValidity(false);
        }
      } else {
        this.updatePasswordValidity(true);
      }
    }
  }

  updatePasswordValidity(validity) {
    if (this.state.emailVisible) {
      this.setState(
        {
          emailPasswordValid: validity
        },
        this.inputStateChanged
      );
    } else {
      this.setState(
        {
          mobilePasswordValid: validity
        },
        this.inputStateChanged
      );
    }
  }

  mobileButtonClicked() {
    this.setState({
      mobileVisible: true,
      emailVisible: false,
      captchaVerified: false
    });
  }

  emailButtonClicked() {
    this.setState({
      mobileVisible: false,
      emailVisible: true
    });
  }

  onKeyDown(e) {
    switch (e.keyCode) {
      case CONSTANTS.ENTER_KEYCODE:
        if (this.state.mobileVisible) {
          if (this.state.isMobileValid && this.state.mobilePasswordValid) {
            this.handleConfirmButton(e);
          }
        } else if (this.state.emailVisible) {
          if (this.state.isEmailValid && this.state.emailPasswordValid) {
            this.handleConfirmButton(e);
          }
        }
        break;
      default:
    }
  }

  TriggerEvents = () => {
    CleverTap_CustomEvents("switchtosignin")
  }

  VerifiyCaptcha = ( returnedValue)=>{
    let Verified = returnedValue;   
        if(Verified){
         this.setState({
          captchaVerified:Verified,
          recaptcha_token:returnedValue
        })
        }
        else{
         this.setState({ expired: "true" });
       }
  }

  /**
   * Component Name - SignUp
   * It returns jsx to be rendered
   * @param null
   * @returns { undefined }
   */
  render() {
    const seoTitle = oResourceBundle.website_meta_title;
    const description = oResourceBundle.website_meta_description;
    const oMetaObject = this.fnConstructMetaTags(
      seoTitle,
      decodeURIComponent(window.location.href),
      description
    );
    const oMetaTags = this.fnUpdateMetaTags(oMetaObject);

    let passwordError = "";
    if (this.state.emailVisible) {
      if (
        !this.state.emailPasswordValid &&
        this.state.emailPassword.length !== 0
      ) {
        passwordError =
          this.state.emailPassword.length >= this.minimumPasswordLength
            ? oResourceBundle.password_alphanumeric
            : oResourceBundle.password_length_error;
      }
    } else if (this.state.mobileVisible) {
      if (
        !this.state.mobilePasswordValid &&
        this.state.mobilePassword.length !== 0
      ) {
        passwordError =
          this.state.mobilePassword.length >= this.minimumPasswordLength
            ? oResourceBundle.password_alphanumeric
            : oResourceBundle.password_length_error;
      }
    }

    return (
      <React.Fragment>
        {oMetaTags}
        {this.props.loading && <Spinner />}
        <div className="signup-container">
          <div className="sign-up">
            <span className="overlay-title">{oResourceBundle.register}</span>
            <div className="register-container">
              <div className="input-container">
                <Button
                  onClick={this.emailButtonClicked.bind(this)}
                  className={
                    "email-button" + (this.state.emailVisible ? " expand" : "")
                  }
                >
                  {oResourceBundle.email}
                </Button>
                {this.state.emailVisible && (
                  <div className="email-expand">
                    <form className="form" name="form">
                      <div className="row">
                        <Input
                          name="email"
                          type="email"
                          placeholder={oResourceBundle.email}
                          className="email"
                          onChange={this.onEmailChange.bind(this)}
                          value={this.state.email}
                          onKeyDown={this.onKeyDown.bind(this)}
                        />
                        {!this.state.isEmailValid &&
                          this.state.email.length > 0 && (
                            <p className="error-message email-error">
                              {oResourceBundle.email_invalid}
                            </p>
                          )}
                      </div>
                      <div className="row">
                        <Input
                          name="password"
                          type="password"
                          placeholder={oResourceBundle.password}
                          className="password"
                          onChange={this.handlePasswordOnChange.bind(this)}
                          value={this.state.emailPassword}
                          onKeyDown={this.onKeyDown.bind(this)}
                        />
                        <p className="error-message password-error">
                          {passwordError}
                        </p>
                      </div>
                    </form>
                    <div className="subscribe-checkbox-wrapper">
                      <div className="checkbox">
                        <Checkbox
                          text={ReactHtmlParser(oResourceBundle.subscribe_to_newsletter1)}
                          onChange={this.handleCheckBox1.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox1}
                          name="subscription-checkbox1"
                        />
                        {/* <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter2}
                          onChange={this.handleCheckBox2.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox2}
                          name="subscription-checkbox2"
                        /> */}
                        <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter3}
                          onChange={this.handleCheckBox3.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox3}
                          name="subscription-checkbox3"
                        />
                        <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter}
                          onChange={this.handleCheckBox.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox}
                          name="subscription-checkbox"
                        />
                      </div>
                    </div>
                    <Button
                      className={
                        "register-button email" +
                        (this.state.isEmailValid &&
                          this.state.emailPasswordValid &&
                          this.state.subscriptionCheckBox1 &&
                          this.state.subscriptionCheckBox2 &&
                          this.state.subscriptionCheckBox3
                          ? " enable"
                          : "")
                      }
                      onClick={this.handleConfirmButton.bind(this)}
                    >
                      {oResourceBundle.register}
                    </Button>
                  </div>
                )}
                <div className="or-container">
                  <div className="or-line" />
                  <div className="or-text">{oResourceBundle.or}</div>
                  <div className="or-line" />
                </div>
                <Button
                  onClick={this.mobileButtonClicked.bind(this)}
                  className={
                    "mobile-button" +
                    (this.state.mobileVisible ? " expand" : "")
                  }
                >
                  {oResourceBundle.mobile}
                </Button>
                {this.state.mobileVisible && (
                  <div className="mobile-expand">
                    <form className="form" name="form">
                      <div className="row">
                        {this.props.countryCode && (
                          <PhoneInput
                            defaultCountry={this.props.countryCode.toLowerCase()}
                            onPhoneChanged={this.onPhoneChanged.bind(this)}
                            onKeyDown={this.onKeyDown.bind(this)}
                          />
                        )}
                        {this.state.phoneNumber.length > 0 &&
                          !this.state.isMobileValid && (
                            <p className="error-message email-phone-error">
                              {oResourceBundle.mobile_invalid}
                            </p>
                          )}
                      </div>
                      <div className="row">
                        <Input
                          name="password"
                          type="password"
                          placeholder={oResourceBundle.password}
                          className="password"
                          onChange={this.handlePasswordOnChange.bind(this)}
                          value={this.state.mobilePassword}
                          onKeyDown={this.onKeyDown.bind(this)}
                        />
                        <p className="error-message password-error">
                          {passwordError}
                        </p>
                      </div>
                    </form>
                    <div className="subscribe-checkbox-wrapper">
                      <div className="recaptcha-wrapper">
                     <Recaptcha isVerified={this.VerifiyCaptcha}/>
                     </div>
                      <div className="checkbox">
                        {/* <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter}
                          onChange={this.handleCheckBox.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox}
                          name="subscription-checkbox"
                        /> */}
                        <Checkbox
                          text={ReactHtmlParser(oResourceBundle.subscribe_to_newsletter1)}
                          onChange={this.handleCheckBox1.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox1}
                          name="subscription-checkbox1"
                        />
                        {/* <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter2}
                          onChange={this.handleCheckBox2.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox2}
                          name="subscription-checkbox2"
                        /> */}
                        <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter3}
                          onChange={this.handleCheckBox3.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox3}
                          name="subscription-checkbox3"
                        />
                        <Checkbox
                          text={oResourceBundle.subscribe_to_newsletter4}
                          onChange={this.handleCheckBox4.bind(this)}
                          customBackground={true}
                          selected={this.state.subscriptionCheckBox4}
                          name="subscription-checkbox4"
                        />
                      </div>
                    </div>
                    <Button
                      className={
                        "register-button mobile" +
                        (this.state.isMobileValid &&
                          this.state.mobilePasswordValid &&
                          this.state.subscriptionCheckBox1 &&
                          this.state.subscriptionCheckBox2 &&
                          this.state.subscriptionCheckBox3 &&
                          this.state.captchaVerified
                          ? " enable"
                          : "")
                      }
                      onClick={this.handleConfirmButton.bind(this)}
                    >
                      {oResourceBundle.register}
                    </Button>
                  </div>
                )}
                {
                  // this.state.serverLogInErrorStatus ? (
                  //   <Dialog
                  //     visible={true}
                  //     onDialogClosed={this.handleSignUpError.bind(this)}
                  //     duration={CONSTANTS.RATING_DIALOG_ANIMATION_DURATION}
                  //     showCloseButton={false}
                  //     closeOnEsc={true}
                  //     width={CONSTANTS.SIGNUP_DIALOG_WIDTH}
                  //     height={CONSTANTS.SIGNUP_DIALOG_HEIGHT}
                  //   >
                  //     <div className="rating-dialog-content">
                  //       <div className="dialog-title">
                  //         {this.state.serverLogInErrorMessage}
                  //       </div>
                  //       <Button
                  //         className="dialog-ok-btn"
                  //         onClick={this.handleSignUpError.bind(this)}
                  //       >
                  //         {oResourceBundle.ok}
                  //       </Button>
                  //     </div>
                  //   </Dialog>
                  // ) : null
                }
              </div>
              <div className="or-container second">
                <div className="or-line" />
                <div className="or-text">{oResourceBundle.or}</div>
                <div className="or-line" />
              </div>
              <SocialLogin />
            </div>
            <div className="redirection-container">
              <div>{oResourceBundle.login_have_account}</div>
              <Link
                key={"redirection"}
                to={`/${this.props.locale}/${CONSTANTS.LOGIN}`}
              >
                <div className="redirection-name" onClick={this.TriggerEvents}>
                  {oResourceBundle.sign_in}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * method that maps state to props.
 * Component - SignUp
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = dispatch => {
  return {
    fnSendNewUserDetails: (
      oCreateAcctUserData,
      fnCreateAcctSuccessful,
      fnCreateAcctError
    ) => {
      dispatch(
        actionTypes.fnSendNewUserDetails(
          oCreateAcctUserData,
          fnCreateAcctSuccessful.bind(this),
          fnCreateAcctError.bind(this)
        )
      );
    },
    fnSaveNewUserDetails: newUserDetails => {
      dispatch(actionTypes.fnSaveNewUserDetails(newUserDetails));
    },
    fnGetTwitterToken: langCode => {
      dispatch(actionTypes.fnGetTwitterToken(langCode));
    },
    stopLoader: () => {
      dispatch(actionTypes.stopLoader());
    }
  };
};

/**
 * Component - SignUp
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = state => {
  return {
    locale: state.locale,
    loading: state.loading,
    countryCode: state.sCountryCode,
    countryList: state.aEnglishCountryList,
    twitterToken: state.twitterToken
  };
};

export default withTracker(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUp)
);
